import { createStore } from 'state-pool';

const web3store = createStore();

// URL Params
web3store.setState('reference', null);
web3store.setState('placementRef', null);
web3store.setState('username', null);

// Funnel
web3store.setState('funnelType', null);

// Wallet
// web3store.setState('w3', null);
web3store.setState('account', '');
web3store.setState('contractbusd', null);
web3store.setState('contractusdpi', null);
web3store.setState('contractbank', null);
web3store.setState('refW3', false); // Signal to refresh wallet connection
web3store.setState('provider', null);
web3store.setState('signer', null);
// Current network
web3store.setState('currentChain', null);
// Required network
web3store.setState('reqChainHex', null);
// web3store.setState('reqChainInt', null);
web3store.setState('chainMode', null);
web3store.setState('skipModal', false);
// Balances
web3store.setState('balusdpi', '');
web3store.setState('balbusd', '');
web3store.setState('balprfti', '');
web3store.setState('balbnb', '');
web3store.setState('baleth', '');
web3store.setState('balusdt', '');
web3store.setState('refBalances', false); // Signal to refresh balances
// Authorizations
web3store.setState('authBusd2Bank', 0);
web3store.setState('authUsdpi2Subs', 0);
// Intake funnel
web3store.setState('ref', '');
web3store.setState('nftimg', ''); // Image to be minted
web3store.setState('showNftConfirm', false);
web3store.setState('nftselected', 0);
web3store.setState('imgCommand', '');
web3store.setState('imgURL', '/static/media/logo-p-purple.6ad0b4f8.svg');
web3store.setState('imgSeed', '');
web3store.setState('invalidRef', false);
// Activation shopping cart
web3store.setState('cartProfitCenters', 1);
web3store.setState('cartPrepayMonths', 12);
web3store.setState('cartAmt', 0);
web3store.setState('cartPurchase', false);
web3store.setState('cartHashes', '');
web3store.setState('cartPurchaseTX', '');
// USDPI Exchange
web3store.setState('usdpiPurchaseAmt', 0);
web3store.setState('usdpiPurchaseAmtEth', 0);
web3store.setState('usdpiPurchaseAmtUsdt', 0);
web3store.setState('usdpiSaleAmt', 0);
web3store.setState('usdpiPurchaseTX', '');
web3store.setState('usdpiAction', 'BUY');
web3store.setState('usdpiPurchaseFee', 0);
web3store.setState('usdpiPurchaseToken', '');
// Staking shopping cart
web3store.setState('buyStakingAmt', 0);
web3store.setState('cartPurchaseStake', false);
web3store.setState('buyStakingHashes', '');
web3store.setState('cartPurchaseStakeTX', '');
// Member / session
web3store.setState('nftlist', []);
web3store.setState('connectedMember', null); // Logged in member's account NFT
web3store.setState('memberStatus', 'entry');
web3store.setState('refNftList', false); // Signal - set to true to refresh member nft list
web3store.setState('wrongChain', false);
// Workflow flags
web3store.setState('activating', false);
web3store.setState('mintOnly', false);
web3store.setState('lastPageServed', '');
web3store.setState('processText', '');
// HarvestStake
web3store.setState('harvestStake', 0);
// No Wallet
web3store.setState('noWallet', false);
// Withdraw
web3store.setState('runWithdrawal', false);
web3store.setState('withdrawalResult', '');
// Claim Revenue Pool USDPI rewards
web3store.setState('runClaimPoolUsdpi', false);
web3store.setState('withdrawalPoolUsdpiResult', '');
// Claim Perf Pool USDPI rewards
web3store.setState('runClaimPerfPoolUsdpi', false);
web3store.setState('withdrawalPerfPoolUsdpiResult', '');
// Claim Drip Prfti rewards
web3store.setState('runClaimDripPrfti', '');
web3store.setState('withdrawalDripResult', '');
// Claim Staking Prfti rewards
web3store.setState('runClaimStakingPrfti', '');
web3store.setState('withdrawalStakingResult', '');
// Add tokens to wallet
web3store.setState('walletAddPRFTI', false);
web3store.setState('walletAddUSDPI', false);
// AI Image generator
web3store.setState('aiigSeed', '');
web3store.setState('aiigPrompt', '');
web3store.setState('aiigResult', []);
web3store.setState('aiigCount', 1);
web3store.setState('aiigCreate', false);
// Balance polling
web3store.setState('balanceInterval', 60000);
web3store.setState('balanceId', null);

export { web3store };
