import { darken } from 'polished';
import styled from 'styled-components';

interface ISelectCoin {
  active: boolean;
  left?: string;
  right?: string;
}

interface ICard {
  background: string;
}

export const RefreshBtn = styled.button`
  background-color: transparent;
  border: none !important;
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    padding-bottom: 2px;
  }
`;

export const ConnectBtn = styled.button`
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  height: 55px;
  display: flex;
  align-items: center;
  span {
    padding: 5px 10px;
    margin: 0 18px;
    display: block;
  }
  @media screen and (max-width: 1399px) {
    font-size: 20px;
  }
`;

export const Container = styled.div`
  font-family: 'Poppins', sans-serif;
  background: #ffffff;
  min-height: 100vh;

  .container-fluid {
    max-width: 1600px;
  }

  .radio-buy-sell {
    display: flex;
    align-items: center;
    input[type='radio'] {
      width: 24px;
      height: 24px;
    }
    label {
      color: #959595;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    input:checked + label {
      color: #000;
    }
  }

  .btn-wallet {
    background: #171719;
    border: 2px solid #837d7d;
    border-radius: 200px;
    color: #ffffff;
    transition-duration: 0.3s;
    height: 55px;
    width: 253px;
    margin-left: auto;
    display: block;

    :hover {
      color: #3ac8a8;
    }
  }

  .logo-usdpi {
    width: 60px;
    height: 60px;
  }

  h2 {
    color: #3a3a3a;
    font-size: 40px;
  }

  .title {
    color: #3a3a3a;
    font-weight: 600;
    font-size: 64px;
    padding-left: 25px;
    margin-top: 25px;
  }

  h4 {
    font-weight: 600;
    font-size: 14px;

    color: #dadada;
  }

  .buy-text {
    font-weight: 600;
    font-size: 14px;
    color: #262728;
    font-style: normal;
    line-height: normal;
  }

  .buy-here {
    background: #0982f2;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    border: none;
    color: #ffffff;
  }

  .import-usdpi {
    background: linear-gradient(85deg, #2fbf7f 10.36%, #3cc9ad 97.55%), #0982f2;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    border: none;
    color: #000;
  }

  .w-45 {
    width: 45%;
  }

  .w-10 {
    width: 10%;
  }

  .text-table {
    font-weight: 600;
    font-size: 18px;
    color: #8d8d8d;
  }

  .wm-tooltip {
    position: relative;
  }

  .wm-tooltip:after {
    background-color: #212122;
    color: #8c8c8c;
    border: 1px solid #262626;
    border-radius: 15px;
    box-sizing: border-box;
    content: attr(data-tooltip);
    display: none;
    padding: 15px;
    font-size: 10px;
    position: absolute;
    right: -109px;
    bottom: 60px;
    z-index: 10000;
    text-align: justify;
  }

  .wm-tooltip:hover:after {
    display: block;
  }

  .estimate {
    font-weight: 400;
    font-size: 12px;
    display: block;
    color: #505253;
  }

  .values {
    font-weight: 600;
    font-size: 20px;
    color: #5e5e5e;
  }

  .text-enough {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ff1a50;
  }

  .bg-number {
    background: rgba(239, 238, 240, 0.3);
    border: 1px solid rgba(57, 60, 61, 0.3);
    border-radius: 20px;

    button {
      top: calc(50% - 16px);
      right: 5%;
      background: #fff;
      border: 1px solid #219653;
      border-radius: 10px;
      font-weight: 700;
      font-size: 12px;
      color: #000;
      padding: 6px 19px;
    }
  }

  .number {
    font-size: 64px;
    color: #262728;
    border-bottom: 1px solid #c4c4c4;
  }

  .color {
    border-top: 1px solid #393c3d;
    color: #5e5e5e;
    width: 300px;
    display: block;
    margin: 0 auto;
  }

  .values span {
    font-weight: 400;
    font-size: 16px;
    color: #505253;
  }

  .color-negative {
    color: #ff1a50;
  }

  .color-positive {
    color: #219653;
  }

  .btn-exchange {
    background: #00f9ab;
    border-radius: 20px;
    transition-duration: 0.3s;
    width: 100%;
    height: 80px;
    font-size: 34px;
  }

  .btn-exchange:hover {
    background: ${darken(0.03, '#00F9AB')};
  }

  .btn-exchange[disabled] {
    background: #ccc; /* Grey background color when disabled */
    cursor: not-allowed; /* Change cursor to "not-allowed" when disabled */
    pointer-events: none; /* Disable pointer events when disabled */
    border: none;
    opacity: 1;
  }

  .cards-shadow {
    filter: drop-shadow(9px 12px 80px rgba(0, 0, 0, 0.25));
  }

  .arrows {
    background-color: #262728;
    padding: 10px 8px;
  }

  .value {
    font-weight: 700;
    font-size: 32px;
    color: #171719;
  }

  .bd-bottom {
    border-bottom: 1px solid #c4c4c4;
  }

  .btn-swap {
    font-weight: 600;
    font-size: 14px;
    background-color: transparent;
    color: #7d7777;
    border: none;
    border-bottom: 2px solid tranparent;
    margin-bottom: -1px;
    transition-duration: 0.3s;
  }

  .btn-swap:disabled {
    color: #7d7777; /* Set the color to the desired disabled color */
    pointer-events: none; /* Disable pointer events on the button */
    border-bottom: 2px solid transparent; /* Remove the border */
  }

  .btn-swap:hover,
  .btn-swap.active {
    border-bottom: 2px solid #96ffd9;
    color: #000;
  }

  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  .modal-xl {
    width: 1140px;
  }

  .input-mask {
    opacity: 1;

    input {
      font-weight: bold;
      text-align: center;
      font-size: 64px;
      color: #262728;

      ::placeholder {
        color: rgba(38, 39, 40, 0.15);
        font-weight: 800;
      }
    }
  }

  .box {
    background: #f9f8fb;
    border-radius: 25px;
    padding: 25px 19px;

    h3 {
      font-weight: 500;
      font-size: 20px;
      color: #161616;
    }

    .tokens-information {
      height: 492px;
      border-radius: 20px;

      ::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
        height: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: rgba(57, 60, 61, 0.3);
      }
    }

    .subbox {
      background: #ffffff;
      border-radius: 20px;
      padding: 16px 22px;
      margin-top: 20px;

      .coin {
        .coin-name {
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          color: #161616;
          margin-left: 3px;
          margin-right: 6px;
        }
      }

      small {
        color: #7d7777;
        font-size: 10px;
        line-height: 15px;
        display: block;
        margin: 11px 0 7px 7px;
      }

      .price {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #171719;
        margin-left: 7px;
        margin-bottom: 0;
      }
    }

    .token-information {
      div.space {
        width: 42px;
        margin-left: 3px;
      }

      .coin {
        width: 115px;

        .coin-name {
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          color: #161616;
          margin-left: 3px;
          margin-right: 6px;
        }
      }

      .arrow-down {
        transform: rotateZ(90deg);
      }

      small {
        font-size: 10px;
        color: #505253;
      }
    }

    + .box {
      margin-top: 13px;
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.75;
    }
  }

  @media screen and (max-width: 1399px) {
    .modal-dialog {
      zoom: 0.65;
    }
  }
`;

export const Card = styled.div<ICard>`
  background: ${(props) => props.background};
  border-radius: 20px;

  h2 {
    font-weight: 700;
    font-size: 40px;
    color: #ffffff;
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 0;
  }
`;

export const LoadingDiscover = styled.div`
  z-index: 2000;
  position: fixed;

  .loading-box {
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.25) -38.97%,
      rgba(0, 0, 0, 0.25) 75.53%
    );
    backdrop-filter: blur(25px);
    span {
      font-weight: 400;
      font-size: 20px;
      color: #fff;
      text-align: center;
    }
  }

  .animation-loading {
    height: 152px;
    width: 152px;

    img {
      position: relative;
      z-index: 300;
      top: 10px;
      left: 10px;
      height: 134px;
      width: 134px;
      background: rgba(0, 0, 0);
      backdrop-filter: blur(5px);
    }
  }

  .box-1 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: #fff;
    animation: position-box-1 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-1 {
    0% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    25% {
      top: calc(100% - 37px);
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    50% {
      top: calc(100% - 114px);
      left: 0;
      width: 37px;
      height: 114px;
    }

    75% {
      top: 0;
      left: 0;
      width: 114px;
      height: 37px;
    }

    100% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }
  }

  .box-2 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: #fff;
    animation: position-box-2 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-2 {
    0% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }

    25% {
      top: 0;
      left: 0;
      width: 37px;
      height: 114px;
    }

    50% {
      top: 0;
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    75% {
      top: calc(100% - 114px);
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    100% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }
  }
`;

export const SelectCoin = styled.div<ISelectCoin>`
  width: 150px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: ${(props) => props.left || 'unset'};
  right: ${(props) => props.right || 'unset'};
  padding: 10px 20px;
  border: 1px solid rgba(57, 60, 61, 0.3);
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};

  hr {
    height: 1px;
    width: 100%;
    background-color: rgba(57, 60, 61, 0.3);
    margin: 5px 0;
  }

  button {
    transition-duration: 0.3s;
    padding: 5px;
    border-radius: 5px;

    :hover {
      background-color: ${darken(0.05, '#ffffff')} !important;
    }
  }
`;
