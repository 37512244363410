import styled from 'styled-components';

interface Props {
  userProfile: boolean;
}
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column; /* Stack child elements vertically */
  align-items: center;
  width: 100%;
  background: #18191a;
  min-height: 100vh;

  @media screen and (max-width: 991px) {
    display: block;
  }
`;

export const HeaderSpacer = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #1d2023;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 60px;
    justify-content: flex-start;
  }
`;

export const Separator = styled.div<Props>`
  background-color: #18191a;
  width: calc(${(props) => (props.userProfile ? '100%' : '100% - 160px')});
  padding: ${(props) => (props.userProfile ? '0' : '20px')};

  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 10px 0;
  }
`;

export const FooterSpacer = styled.div`
  width: 35%;
  background: #202020;
`;
