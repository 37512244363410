import React, { useCallback, useState } from 'react';

import { ModalUpdate } from './styles';

import aiverifyGif from '~/assets/animations/aiverify.gif';
import updateRequired from '~/assets/defaults/update-required.png';
import updateComplete from '~/assets/defaults/update-complete.png';

interface IProps {
  showUpdate: boolean;
  showUpdateComplete: boolean;
  handleCloseUpdate: () => void;
  handleCloseUpdateComplete: () => void;
  handleUpdateBtn: () => void;
  handleSwapBtn: () => void;
}

const ModalClearApproval: React.FC<IProps> = ({
  showUpdate,
  showUpdateComplete,
  handleCloseUpdate,
  handleCloseUpdateComplete,
  handleUpdateBtn,
  handleSwapBtn,
}) => {
  return (
    <>
      <ModalUpdate
        size="xl"
        show={showUpdate}
        onHide={handleCloseUpdate}
        className="modal-update"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleCloseUpdate}
        >
          x
        </button>
        <ModalUpdate.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <button
            type="button"
            className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
            onClick={handleCloseUpdate}
          >
            x
          </button>
          <div className="my-5">
            <img src={updateRequired} alt="aiverify" className="header-image" />
          </div>
        </ModalUpdate.Header>
        <ModalUpdate.Body className="px-4 px-sm-5">
          <h1 className="text-center">Update Required</h1>
          <p>
            We've noticed that there's an existing approval for USDT in your
            wallet that needs to be updated before you can proceed with
            exchanging USDT for USDPI. This is a standard security step to
            ensure that your transaction occurs smoothly
          </p>
          <h2 className="mt-4 mt-lg-0 fw-bold text-center w-100">
            What Needs to Happen:
          </h2>
          <p>
            First, we need to reset your current USDT approval to zero. This is
            a necessary step to accurately set it to the new amount required for
            your exchange.
          </p>
          <h2 className="mt-4 mt-lg-0 fw-bold text-center w-100">
            How to Proceed:
          </h2>
          <p>
            Simply click the 'Reset Approval' button below. This will reset the
            existing approval and allow you to move forward with your
            transaction securely and efficiently.
          </p>
          <h2 className="mt-4 mt-lg-0 fw-bold text-center w-100">
            Why This Step Is Important:
          </h2>
          <p>
            This ensures that only the correct amount of USDT is approved for
            exchange, aligning with your intended transaction and enhancing
            security.
          </p>
          <p>
            After you've cleared the existing approval, you'll be all set to
            proceed with your USDT to USDPI exchange. Thank you for your
            understanding and cooperation!
          </p>
        </ModalUpdate.Body>
        <ModalUpdate.Footer className="border-0 mt-5 px-4 pb-5 flex-column">
          <button
            type="button"
            className="border-0 text-white py-3 fw-bold"
            onClick={handleUpdateBtn}
          >
            CLICK HERE TO RESET APPROVAL
          </button>
        </ModalUpdate.Footer>
      </ModalUpdate>
      <ModalUpdate
        size="xl"
        show={showUpdateComplete}
        onHide={handleCloseUpdateComplete}
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleCloseUpdateComplete}
        >
          x
        </button>
        <ModalUpdate.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          {/* <div className="my-n4 my-lg-n5 d-flex align-items-center justify-content-center overflow-hidden"> */}
          <button
            type="button"
            className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
            onClick={handleCloseUpdateComplete}
          >
            x
          </button>
          <div className="my-5">
            <img src={updateComplete} alt="aiverify" className="header-image" />
          </div>
        </ModalUpdate.Header>
        <ModalUpdate.Body className="px-4 px-sm-5">
          <h1 className="text-center">Great Job!</h1>
          <p>
            You've successfully reset your USDT approval. Your wallet is now
            ready for the USDT to USDPI exchange. We appreciate your quick
            action in making sure everything is set up correctly for your
            transaction.
          </p>
          <h2 className="mt-4 mt-lg-0 fw-bold text-center w-100">Next Step:</h2>
          <p>
            You're all set to proceed with your exchange. Just click the
            'Proceed with Exchange' button below to start the process. We're
            here to ensure your transaction is safe and seamless.
          </p>
          <h2 className="mt-4 mt-lg-0 fw-bold text-center w-100">
            Why This Matters:
          </h2>
          <p>
            Resetting your approval ensures that your exchange is processed with
            the correct parameters, reflecting your current transaction needs.
            It's all about keeping your transactions secure and aligned with
            your intentions.
          </p>
          <p>
            We're excited to assist you in this exchange and are here for any
            questions or assistance you might need. Let's go ahead and complete
            your transaction!
          </p>
        </ModalUpdate.Body>
        <ModalUpdate.Footer className="border-0 mt-5 px-4 pb-5 flex-column">
          <button
            type="button"
            className="border-0 text-white py-3 fw-bold"
            onClick={handleSwapBtn}
          >
            Proceed with Exchange
          </button>
        </ModalUpdate.Footer>
      </ModalUpdate>
    </>
  );
};

export default ModalClearApproval;
