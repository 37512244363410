import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Button = styled.button`
  :disabled {
    opacity: 0.7;
  }
`;

export const ModalUpdate = styled(ModalComponent)`
  // .modal-dialog {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100% !important;
  //   // backdrop-filter: blur(5px) !important;
  //   // z-index: 10 !important;
  // }
  .modal-content {
    background-color: #f4f7fd !important;
    border-radius: 40px;
    position: relative;
    padding-left: 80px;
    padding-right: 80px;

    p {
      /* width: 894px; */
      text-align: center;
      color: #303030;
      text-align: center;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 118%; /* 25.96px */
    }

    h1 {
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%; /* 59.52px */
      letter-spacing: 0.96px;
      margin-bottom: 28px;
    }

    h2 {
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 118%; /* 25.96px */
    }

    .header-image {
      width: 265px;
      height: 194px;
    }

    img {
      width: 750px;
      margin: -4rem;
    }

    .modal-close {
      color: #000;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .my-n5 {
      margin: -3rem 0;
    }

    .modal-footer {
      z-index: 1;

      p {
        font-family: 'Poppins';
        color: #c4c4c4;
        font-size: 22px;
        line-height: 33px;
      }

      button {
        width: 768.41px;
        max-width: 100%;
        background: #0081f9;
        border-radius: 20px;
        font-size: 24px;
        transition-duration: 0.3s;
        :hover {
          opacity: 0.9;
        }
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;
