import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// import Header from '~/components/HeaderPrivate'; // ************
// import ModalDisconnected from '~/components/ModalDisconnected';

import { Wrapper, HeaderSpacer, Separator } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const [unshowMenu, setUnshowMenu] = useState(false);

  return <Wrapper>{children}</Wrapper>;
};

export default DefaultLayout;
