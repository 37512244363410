import React, { FC, ComponentType, useMemo, useEffect } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouterProps,
  Redirect,
} from 'react-router-dom';

// import { useAuth } from '../hooks/Auth';

// import Auth from '~/pages/_Layouts/Auth';
// import Default from '~/pages/_Layouts/Default';
import Swap from '~/pages/_Layouts/Swap';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  component: ComponentType;
}

const Route: FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  // const { connected, user } = useAuth();
  // console.log('+++++ rest +++++');
  // console.log(rest);
  // useEffect(() => {
  //   // console.log(connected);
  //   // console.log(isPrivate);
  // }, [connected, isPrivate]);

  const Layout = useMemo(() => {
    return Swap;
  }, [isPrivate]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Layout>
            <Component />
          </Layout>
        );
      }}
    />
  );
};

export default Route;
