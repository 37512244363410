import React from 'react';

import { Modal } from './styles';

import walletExchange from '~/assets/defaults/wallet-exchange.png';

interface IShow {
  show: boolean;
  handleClose(): void;
  hash: string | null;
}

const ModalExchangeSuccessful: React.FC<IShow> = ({
  show,
  handleClose,
  hash,
}) => {
  const explorerURL = process.env.REACT_APP_PAY_BLOCK_EXPLORER_URLS;

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      className="modal-wrong-network"
    >
      <button
        type="button"
        className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
        onClick={handleClose}
      >
        x
      </button>
      <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
        <div className="mt-n5 d-flex align-items-center justify-content-center overflow-hidden">
          <img src={walletExchange} alt="Wallet" />
        </div>
      </Modal.Header>
      <Modal.Body className="px-4 px-sm-5">
        <h2 className="mt-n5 mb-4 fw-bold text-center w-100">
          Exchange Successful!
        </h2>
        <div className="texts w-75 mx-auto">
          <p className="text-center my-5 px-xl-2">
            Your exchange was successfully completed and funds should arrive in
            your wallet in a few moments.
          </p>

          {hash !== null && (
            <span>
              <p className="text-center">Here is the transaction hash:</p>
              <div className="mb-5">
                <a
                  href={`${explorerURL}/tx/${hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {hash}
                </a>
              </div>
            </span>
          )}
          <button
            type="button"
            onClick={handleClose}
            className="btn-confirm w-100 mt-0 mb-4"
          >
            <span className="">Back</span>
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 py-4" />
    </Modal>
  );
};

export default ModalExchangeSuccessful;
